import React from 'react'
import ReactDOM from 'react-dom'
import Core from './components/core'





ReactDOM.render(<Core/>, document.getElementById('root'));

